@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap");
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html {
  scroll-behavior: smooth;
}

/* body.dark-mode {
  --background-color: #000210;
  --color: #ffffff;
  --border-color: #cecece48;
}
body.light-mode {
  --background-color: #000210;
  --color: #ffffff;
  --border-color: #cecece;
} */

@font-face {
  font-family: "GeneralSans";
  src: url("../font/GeneralSans-Medium.otf");
}

body {
  background: #000210;
  color: #ffffff;
  height: 100;
  font-family: "GeneralSans";
}
body.light-mode .modechange-sun-icon {
  display: none;
}
body.dark-mode .modechange-moon-icon {
  display: none;
}
.offcanvas {
  background: #000210;
  color: #ffffff;
}
.btn-close {
  opacity: 1;
}
.offcanvas-header .btn-close {
  background-image: url("../images/banner/cross.white.svg") !important;
}
img {
  width: 100%;
  height: 100%;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-track {
  width: 5px;
  height: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1b9ffe;
}

/* Typography css begin */
h5 {
  font-size: 1rem;
}

a {
  color: inherit !important;
  text-decoration: none;
}

.navbar-brand img {
  width: 8vw;
  height: auto;
}

/* @keyframes translateX {
  0% {
    opacity: 0;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    width: 0rem;
  }
  100% {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    width: 42rem;
  }
} */
@keyframes translateX {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 1;
}
body.dark-mode .light-mode-hide {
  display: none;
}
body.light-mode .dark-mode-hide {
  display: none;
}
.navbar-toggler {
  background: rgba(255, 255, 255, 0.425);
  backdrop-filter: blur(44px);
  -webkit-backdrop-filter: blur(44px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
.fs-12 {
  font-size: 12px;
}
.DChainStarterKit {
  color: #1b9ffe !important;
  text-decoration: underline;
}
.btn-close {
  --bs-btn-close-color: #000;
  /* --bs-btn-close-bg: url("../images/banner/cross.white.svg"); */
  box-shadow: none !important;
}

.navbar-fixed-top-section {
  position: fixed;
  top: 50px;
  right: 0;
  left: 0;
  z-index: 1030;
}
.top-navbar-marquee-container {
  width: 100%;
  height: 50px;
  vertical-align: middle;
  background-color: #000;
  font-size: 1.06rem;
  font-weight: 400;
  display: table-cell;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: sans-serif;
}
.marquee-icon-1 {
  margin-bottom: 4px;
  margin-left: 8px;
  font-size: 1.3rem;
}
.marquee-text-1 {
  margin-left: 70px;
}
.overlay::before,
.overlay::after {
  background: none !important;
  content: "";
  height: 100%;
  position: absolute;
  width: 0px;
  z-index: 2;
}
.marquee-container a {
  cursor: pointer;
}
.road-arrow-1,
.road-arrow-2,
.road-arrow-3,
.road-arrow-4 {
  display: none;
}

.banner-section-2-2 a {
  color: #fff;
  text-decoration: none;
  position: relative;
}
.banner-section-2-2 a:after,
.banner-section-2-2 a:after {
  bottom: -4px;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  background: #fff;
  opacity: 0.6;
  transition: width 0.3s ease 0s, opacity 0.3s ease 0s;
  width: 0;
}
.banner-section-2-2 a:hover:after,
.banner-section-2-2 a:hover:after {
  width: 100%;
  opacity: 0.9;
}
.banner-section-list-tabs a {
  color: #fff;
  text-decoration: none;
  position: relative;
  font-size: 13px;
}
.banner-section-list-tabs a:after,
.banner-section-list-tabs a:after {
  bottom: -4px;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  background: #ffffff4d;
  opacity: 0.6;
  transition: width 0.3s ease 0s, opacity 0.3s ease 0s;
  width: 100%;
}
.banner-section-list-tabs a:hover:after,
.banner-section-list-tabs a:hover:after {
  width: 0%;
  opacity: 0.9;
}
.navbar-brand-footer-kyc {
  width: 60px;
  margin-left: 40px;
  transition: transform 0.2s; /* Animation */
}
.navbar-brand-footer-kyc:hover {
  transform: scale(
    7.5
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.made-by-decosystem-banner-section img {
  height: auto !important;
}
.made-by-decosystem-banner-section .card:hover {
  border: 1px solid #00e5ff42;
}
.buy-coin-links-logo-size {
  width: 100px;
  height: 100px;
}
.buy-coin-links-icons-css {
  color: #f1ba34 !important;
  font-size: 20px;
}
.roundcircles-images {
  opacity: 0.8;
  height: auto !important;
}
.listet-logo-image-hover-1:hover {
  transform: scale(
    2
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.sub-content li {
  list-style: lower-roman;
}

/* ============ DESKTOP-VIEW ============  */

@media all and (min-width: 992px) {
  .new-hackathon-banner-section-2 .card-title{
    margin-bottom: 0px;
    font-size: 1.4rem;
    padding: 10px;
  }
  .new-hackathon-banner-section-2 .card{
    background-color: #222d40;
    color: #FFF;
    text-align: center;
  }
  .new-hackathon-button-1{
    background-color: #1a9efd;
    font-size: 20px;
    padding: 15px 30px;
    border-radius: 4px;
    cursor: pointer;
  }
  .hackathon-text-5{
    font-size: 1.2rem;
  }
  .hackathon-text-4{
    font-size: 2.8rem;
    font-weight: bold;
    letter-spacing: 2px;
  }
  .hackathon-text-3{
    font-size: 1.8rem;
    font-weight: bold;
  }
  .hackathon-text-2{
    width: 60%;
    margin: auto;
    margin-bottom: 6%;
  }
  .hackathon-text-1{
    margin-top: 1%;
    margin-bottom: 1%;
    font-size: 7vw;
    font-weight: 100;
    letter-spacing: 5px;
  }
  .projects-build-starting-section .card-box img:hover {
    transform: scale(1.5);
  }
  .projects-build-starting-section .card-box img {
    width: 50%;
    margin: auto;
    transition: transform 0.2s; /* Animation */
  }
  .projects-build-starting-section .border-right-0 {
    border-right: 0px solid #ffffff46 !important;
  }
  .projects-build-starting-section .card-box {
    background-color: transparent;
    border-right: 1px solid #ffffff46;
    border-radius: 0px;
    text-align: center;
    /* border: 0px; */
  }

  .projects-build-starting-section .col {
    box-shadow: #00e5ff09 0px 30px 60px -12px inset,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    padding: 20px;
    border: 1px solid #0084ff09;
    border-radius: 4px;
  }
  .projects-build-starting-section .col {
    margin-top: 0px;
  }
  .listet-logo-image-2 {
    z-index: 999;
    width: 50px;
    height: 50px;
    position: relative;
    top: -80px;
    transition: transform 0.2s; /* Animation */
  }
  .listet-logo-image-1 {
    z-index: 999;
    width: 50px;
    height: 50px;
    position: relative;
    top: 80px;
    transition: transform 0.2s; /* Animation */
  }

  .made-by-text-2 {
    font-size: 12px;
    margin-bottom: 0px;
    opacity: 0.8;
  }
  .made-by-text-1 {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 6px;
    letter-spacing: 1px;
  }
  .made-by-decosystem-banner-section .card {
    padding: 20px 10px;
    height: 100%;
    background: rgba(27, 159, 254, 0.1);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    border: 1px solid #00e5ff11;
    border-radius: 12px;
  }
  .featured-by-section img {
    width: 80%;
  }

  .sniper-listing-logo {
    height: 55px;
    width: 90% !important;
    margin: auto;
  }
  .Partneradvisor-listing-banner-section .card {
    text-align: center;
    border: none;
    background: #031228;
    padding: 30px 30px;
    height: 100%;
  }
  .Partneradvisor-listing-banner-section .card img {
    width: 42%;
    height: auto;
  }

  .Partner-listing-banner-section .card img {
    height: 55px;
    width: 80%;
    margin: auto;
    transition: transform 0.8s; /* Animation */
  }
  .Partner-listing-banner-section .card {
    text-align: center;
    border: none;
    background: #031228;
    padding: 30px 30px;
    height: 100%;
    transition: transform 0.8s; /* Animation */
  }
  .Partner-listing-banner-section .card:hover img {
    transform: scale(
      1.2
    ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  .container-glow .btn-kyc-banner {
    position: relative;
    width: 175px;
    height: 50px;
    margin-right: 20px;
  }
  .popup-text-1 {
    font-size: 2.4rem;
    text-transform: uppercase;
    margin-bottom: 0px;
    font-weight: 800;
    line-height: 2.4rem;
    animation-name: fadefromtop;
    animation-duration: 0.8s;
    animation-delay: 0.9s;
    animation-fill-mode: both;
  }
  .popup-text-2 {
    font-size: 7rem;
    text-transform: uppercase;
    margin-bottom: 0px;
    font-weight: 1000;
    line-height: 8rem;
    animation-name: fadefromleft;
    animation-duration: 0.8s;
    animation-delay: 0.9s;
    animation-fill-mode: both;
  }
  .popup-text-3 {
    font-size: 1.1rem;
    letter-spacing: 0.03em;
    margin-bottom: 2.5rem;
    animation-name: fadefromleft;
    animation-duration: 1.1s;
    animation-delay: 1.2s;
    animation-fill-mode: both;
  }
  .stepImge-text-2 {
    font-size: 0.8rem;
  }
  .stepImge-text-1 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 40px;
  }
  .stepImg-tabs img {
    width: 60px;
    height: 60px;
    object-fit: contain;
  }
  .stepImg-tabs {
    width: 111px;
    height: 111px;
    box-shadow: 0 16px 24px rgb(74 144 226 / 10%);
    border-radius: 100%;
    background: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .css-border-link {
    border-top: 1px solid #1b9ffe;
    width: 70%;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    top: 80px;
    z-index: -2;
  }
  .build-flip-image-section:hover .build-flip-image-1 {
    transform: skew(0deg, 0deg);
  }
  .build-flip-image-section:hover .build-flip-image-2 {
    transform: skew(0deg, 0deg);
    position: relative;
    top: 10px;
  }
  .build-flip-image-1 {
    width: 60%;
    transform: skew(-15deg, 0deg);
    z-index: 999;
    transition: 0.6s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  .build-flip-image-2 {
    width: 60%;
    transform: skew(-15deg, 0deg);
    position: relative;
    top: -50px;
    z-index: -1;
    transition: 0.6s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  .banner-section-2-2 .card img {
    width: 45px;
    height: 45px;
  }
  .banner-section-2-2 a {
    font-size: 0.8rem;
  }
  .banner-section-2-2 .card-text {
    font-size: 0.8rem;
    margin-bottom: 4px;
  }
  .banner-section-2-2 .card-title {
    font-size: 1.4rem;
    font-weight: 900;
    letter-spacing: 1px;
  }
  .banner-section-2-2 .card {
    background: transparent;
    border: none !important;
  }
  .idolive-button-image {
    height: 127px;
    position: absolute;
    top: -120px;
    width: 24vw;
  }
  .banner-section-top-padding {
    position: relative;
    top: 50vh;
    z-index: 999;
  }
  .banner-video-1 {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    object-fit: cover;
    z-index: -9;
    /* opacity: 0.2; */
  }
  .blogreadmore-aditional-image-1 {
    width: 90px;
    height: auto;
  }
  .blogreadmore-aditional-text-1 {
    font-size: 12px;
    margin-bottom: 0px;
  }

  .blog-read-more-section {
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    grid-gap: 30px;
    justify-content: center;
  }
  .blogreadmore-aditional-section .card {
    padding: 20px;
    margin-bottom: 30px;
    border: 1px solid #cecece13;
    border-radius: 8px;
    background: rgba(27, 159, 254, 0.034);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }
  .banner-section-66 .card:hover .card-body {
    padding: 60px 70px 47px;
  }
  .banner-section-66 .card-body {
    padding: 75px 70px 32px;
    display: flex;
    flex-direction: column;
    text-align: center;
    transition: 0.2s;
  }
  .banner-button-1 {
    cursor: pointer;
    border: 1px solid transparent;
    background-image: linear-gradient(rgba(19, 20, 25, 0), rgba(19, 20, 25, 0)),
      linear-gradient(
        108.46deg,
        rgba(66, 176, 255, 0.5) 12.51%,
        rgba(103, 109, 255, 0.5) 51.13%,
        rgba(141, 138, 255, 0.5) 87.49%
      );
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    color: rgb(255, 255, 255);
    white-space: nowrap;
    background-color: transparent !important;
    padding: 0px !important;
    height: 42px;
  }
  .banner-button-1 > div {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64)),
      linear-gradient(
        108.46deg,
        rgba(66, 176, 255, 0) 12.51%,
        rgba(103, 108, 255, 0) 51.13%,
        rgba(142, 138, 255, 0) 87.49%
      );
    border-radius: 8px;
    padding: 10px 24px;
  }

  .banner-section-66 img {
    width: 4vw;
    height: 4vw;
    margin-bottom: -2vw;
    position: relative;
    z-index: 999;
  }
  .banner-section-6-text-2 {
    color: #b1b1b1;
    font-size: 0.9rem;
    line-height: 1.4;
    font-weight: 500;
  }
  .banner-section-6-text-1 {
    font-size: 2.15rem;
    line-height: 1.2;
    font-weight: 500;
  }
  .banner-section-66 .card {
    backdrop-filter: blur(10px);
    position: relative;
    background: linear-gradient(rgb(0, 2, 16), rgb(0, 2, 16)) border-box
        content-box,
      linear-gradient(
          304.78deg,
          rgba(245, 251, 242, 0.247) -6.31%,
          rgba(245, 251, 242, 0) 110.8%
        )
        border-box;
    border-radius: 15px;
    overflow: hidden;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    transition: 0.2s;
  }

  .banner-section-66 .card::before {
    content: "";
    background-image: url("../images/card-bg-glow.png");
    width: 117px;
    height: 120px;
    display: block;
    position: absolute;
    filter: blur(40px);
    left: calc(50% - 59px);
    top: -50%;
  }

  .contact-text-1 {
    font-size: 0.8rem;
    letter-spacing: 0.5px;
  }
  .manualconnect-text-2 {
    font-size: 0.85rem;
    opacity: 0.9;
    letter-spacing: 0.062em;
  }
  .manualconnect-tool-tip {
    background-color: #fff;
    color: #000;
    font-size: 0.75rem;
    font-weight: 400;
  }
  .manualconnect-text-1 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 4px;
  }
  .manualconnect-icon-1 {
    font-size: 0.8rem;
    margin-bottom: 2px;
  }
  .manualconnect-modal-section {
    background: rgba(27, 159, 254, 0.1);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }
  .manualconnect-modal-section .modal-content {
    background: rgba(27, 159, 254, 0.1);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border: 1px solid #00e5ff21;
    border-radius: 12px;
  }
  .banner-top-fixed-timer-rool-image {
    width: 26vh;
    height: 26vh;
    position: fixed;
    top: calc(100vh - 26vh);
    left: 0px;
    z-index: 999;
  }
  .blogread-text-3 {
    margin-bottom: 2rem;
    font-size: 1.25rem;
    line-height: 1.6;
    font-weight: 400;
  }
  .blogread-text-1 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 2.675rem;
    line-height: 1.2;
    font-weight: 500;
  }
  .blogread-text-2 {
    color: #1b9ffe;
    font-size: 12px;
    text-transform: uppercase;
  }
  .banner-video-bottom-1 {
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    object-fit: cover;
    z-index: -9;
    opacity: 0.4;
  }
  .roadmap-button-1 {
    position: relative;
    right: -100px;
    background: rgba(27, 159, 254, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px;
    color: #ffffff;
    border: 0px;
    padding: 8px 22px;
    border-radius: 25px;
    font-size: 0.85rem;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  main aside.sidebar-left {
    border-right: 1px solid #dfdfdf;
    font-size: 1rem;
  }
  main {
    display: grid;
    grid-template-columns: 1.3fr 4fr;
    grid-gap: 30px;
  }
  .d-chain-pos-banner-section .accordion-button::after {
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: 12px;
    transition: var(--bs-accordion-btn-icon-transition);
  }

  .d-chain-scroll-container {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
  .d-chain-scroll-container li {
    font-size: 0.9rem;
  }
  .d-chain-pos-banner-section .nav-link {
    color: #fff !important;
  }
  .d-chain-pos-banner-section .sidebar-left a {
    font-size: 14px;
    text-transform: capitalize;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-weight: 600;
  }
  .d-chain-scroll-container h5 {
    font-size: 1.1rem;
    font-weight: 600;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
  .d-chain-scroll-container h4 {
    font-size: 1.5rem;
    font-weight: 600;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    margin-top: 100px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  .d-chain-scroll-container p {
    font-size: 0.9rem;
    font-weight: 400;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    margin-bottom: 30px;
  }
  .dchain-text-1 {
    font-size: 18px;
  }

  .d-chain-pos-banner-section .accordion-button:not(.collapsed) {
    color: #fff;
    background-color: transparent;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
      var(--bs-accordion-border-color);
  }
  .d-chain-pos-banner-section .accordion {
    --bs-accordion-btn-active-icon: url("../images/banner/arrow.svg");
    --bs-accordion-btn-icon: url("../images/banner/arrow-right.svg");
    box-shadow: none !important;
  }
  .d-chain-pos-banner-section .accordion-item {
    color: var(--bs-accordion-color);
    background-color: transparent;
    border: none !important;
    box-shadow: none !important;
  }
  .d-chain-pos-banner-section .accordion-button {
    box-shadow: none !important;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 10px;
    font-size: 1rem;
    color: #fff;
    text-align: left;
    background-color: #000210;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    border-color: #000210;
    transition: var(--bs-accordion-transition);
  }

  .mediakit-top-section .dropdown-menu {
    right: 10px;
    --bs-dropdown-min-width: 8rem;
  }
  .mediakit-top-section .card {
    padding: 10px;
    background: rgba(27, 159, 254, 0.041);
    backdrop-filter: blur(20px);
    color: #fff;
    z-index: 1;
  }
  .Error404-image-home-button {
    position: relative;
    top: -80px;
    width: 200px;
    height: 40px;
    margin: auto;
  }
  .Error404-image {
    width: 50%;
    margin: auto;
  }
  .event-banner-section .card {
    background-color: transparent;
    border: 1px solid #dee2e627;
    padding: 50px 10px;
    font-size: 14px;
  }
  .event-banner-section p {
    margin-bottom: 6px;
  }
  .event-banner-section .swiper {
    padding-bottom: 100px;
    width: 90%;
    margin-top: 50px;
  }
  .event-banner-section .swiper-button-prev,
  .event-banner-section .swiper-rtl .swiper-button-next {
    left: auto;
    right: 80px;
  }
  .event-banner-section .swiper-button-next,
  .event-banner-section .swiper-rtl .swiper-button-prev {
    left: auto;
    right: 20px;
  }
  .event-banner-section .swiper-button-next:after,
  .event-banner-section .swiper-button-prev:after {
    font-size: 16px;
  }
  .event-banner-section .swiper-button-next,
  .event-banner-section .swiper-button-prev {
    position: absolute;
    top: 86%;
    z-index: 10;
    width: 2.635rem;
    height: 2.625rem;
    border-style: none;
    background: rgba(27, 159, 254, 0.1);
    backdrop-filter: blur(20px);
    color: #fff;
    border-radius: 50%;
  }

  .join-event-button {
    background: rgba(27, 159, 254, 0.986);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px;
    color: #ffffff;
    border: 0px;
    padding: 8px 22px;
    border-radius: 25px;
    font-size: 0.85rem;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .event-banner-section .nav-pills .nav-link.active,
  .event-banner-section .nav-pills .show > .nav-link {
    background-color: #1b9ffe;
  }
  .event-banner-section .nav-pills {
    background: rgba(27, 159, 254, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    margin: auto;
    width: fit-content;
    padding: 5px;
    border-radius: 50px;
  }
  .event-banner-section .nav-pills .nav-link {
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #ffffff !important;
    text-transform: capitalize;
    border-radius: 50px;
  }
  .event-banner-section {
    padding: 100px 80px;
    border: 1px solid #cecece09;
    border-radius: 8px;
    background: rgba(27, 159, 254, 0.021);
    border-radius: 12px;
  }
  .internal-page-padding-top {
    padding-top: 10rem;
  }
  .footer-typo-icons {
    color: #ffffff !important;
    position: relative;
    left: -6px;
    opacity: 0;
    transition: 0.3s;
  }
  .footer-typo a {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1rem;
    margin-bottom: 1.3rem;
    color: #67666e !important;
    transition: color 0.2s;
  }
  .footer-typo a:hover .footer-typo-icons {
    left: 2px;
    opacity: 1;
  }
  .footer-typo a:hover {
    color: #ffffff !important;
  }
  .footer-text-1 {
    font-size: 1rem;
    margin-bottom: 1.25rem;
  }
  .navbar-brand-footer {
    width: 150px;
    height: 70px;
  }
  .footer-social-icons-link a {
    cursor: pointer;
    margin-left: 2rem;
    font-size: 1.3rem;
    transition: color 0.2s;
    color: #67666e !important;
  }
  .footer-social-icons-link a:hover {
    color: #ffffff !important;
  }
  .banner-section-8 .input-group {
    background: rgba(27, 159, 254, 0.1);
    -webkit-backdrop-filter: blur(60px);
    backdrop-filter: blur(60px);
    border-radius: 10px;
    border: 1px solid #00e5ff17;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
  }
  .banner-section-8 .input-group:hover {
    border: 1px solid #1b9ffe6c;
  }
  .banner-section-8 .input-group-text {
    margin-left: 0px !important;
    box-shadow: none !important;
    min-height: 3.75rem;
    padding: 1.25rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    background: transparent;
    border: 0px solid #2d2c33 !important;
    border-radius: 0.375rem;
  }
  .banner-section-8 ::placeholder {
    color: #ffffff !important;
    font-size: 15px;
  }
  .banner-section-8 .form-control {
    box-shadow: none !important;
    min-height: 3rem;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    color: #fff;
    background: transparent;
    border: 0px solid #2d2c33;
  }
  .banner-section-8 .form-control:focus {
    color: #fff;
    background: transparent;
    border: 0px solid #2d2c33;
  }
  .stay-mail-text-2 {
    font-size: 2rem;
  }
  .stay-mail-text-1 {
    font-size: 10px;
    letter-spacing: 2px;
    color: #1b9ffe;
  }
  .banner-section-7 .card-text {
    margin-top: 30px;
    font-size: 1.25rem;
    line-height: 1.6;
    font-weight: 400;
  }
  .blog-text-1 {
    font-size: 10px;
    text-transform: uppercase;
  }
  .blog-text-2 {
    font-size: 10px;
    color: #1b9ffe;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .banner-section-6-glow-1 {
    position: absolute;
    width: 300px;
    height: 300px;
    /* top: -60px;
    left: -60px; */
  }
  .banner-section-6-glow-2 {
    position: absolute;
    width: 300px;
    height: 300px;
    bottom: -60px;
    left: 75%;
  }
  .banner-section-7 .card-body {
    padding: 30px 30px;
  }
  .banner-section-7 .card {
    border: 1px solid #cecece13;
    border-radius: 20px;
    background: rgba(32, 88, 128, 0.1);
    cursor: pointer;
    transition: 0.2s; /* Animation */
  }
  .banner-section-7 .card-body {
    transition: 0.2s; /* Animation */
  }

  .banner-section-7 .card:hover {
    transform: scale(
      1.01
    ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .banner-content {
    position: absolute;
    top: 4%;
    left: 80%;
    display: none;
    font-size: 40px;
  }
  .banner-section-7 .card:hover .banner-content {
    display: block;
  }
  .banner-section-7 .card:hover {
    background-color: #1b9ffe15;
  }
  .banner-section-7 .card:hover .card-body {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .banner-section-7 .card img {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    width: 100%;
    height: 180px;
  }
  .banner-section-7 .swiper-button-prev,
  .banner-section-7 .swiper-rtl .swiper-button-next {
    left: auto;
    right: 200px;
  }
  .banner-section-7 .swiper-button-next,
  .banner-section-7 .swiper-rtl .swiper-button-prev {
    left: auto;
    right: 120px;
  }
  .banner-section-7 .swiper-button-next:after,
  .banner-section-7 .swiper-button-prev:after {
    font-size: 16px;
  }
  .banner-section-7 .swiper-button-next,
  .banner-section-7 .swiper-button-prev {
    position: absolute;
    top: 86%;
    z-index: 10;
    width: 2.635rem;
    height: 2.625rem;
    border-style: none;
    background: rgba(27, 159, 254, 0.1);
    -webkit-backdrop-filter: blur(60px);
    backdrop-filter: blur(60px);
    border-radius: 10px;
    color: #fff;
    border-radius: 50%;
  }
  .banner-section-7 .swiper {
    padding-bottom: 200px;
  }
  .banner-section-6-middle-card {
    position: relative;
    top: 150px;
  }

  .banner-section-6 .card {
    background: rgba(27, 159, 254, 0.1);
    -webkit-backdrop-filter: blur(60px);
    backdrop-filter: blur(60px);
    border-radius: 10px;
    border: 1px solid #00e5ff17;
    border-radius: 12px;
    padding-top: 250px;
    transition: 0.2s; /* Animation */
  }
  .banner-section-6 .card:hover {
    background: rgba(27, 159, 254, 0.205);
    -webkit-backdrop-filter: blur(60px);
    backdrop-filter: blur(60px);
    padding-top: 230px;
    padding-bottom: 20px;
  }
  .banner-section-6 {
    background: rgba(0, 229, 255, 0.008);
    padding-bottom: 200px !important;
    /* background-image: url("../images/banner/Vector\ Smart\ Object.png");
    background-size: 100% 100%; */
    background-repeat: no-repeat;
  }
  .banner-section-2-icon-1 {
    font-size: 6rem;
    padding: 20px;
    margin-bottom: 30px;
    border: 1px solid #cecece13;
    border-radius: 8px;
    background: rgba(27, 159, 254, 0.1);
    backdrop-filter: blur(20px);
  }
  .banner-section-2 .card:hover .banner-section-2-icon-1 {
    animation: animName 3s linear infinite;
  }
  @keyframes animName {
    0% {
      transform: rotateY(0deg);
    }
    100% {
      transform: rotateY(360deg);
    }
  }
  .glow-effects-circle-image-1 {
    width: 200px;
    height: 200px;
    position: absolute;
    right: 14%;
    top: 1%;
  }
  .banner-section-3 .nav-pills .nav-link.active,
  .banner-section-3 .nav-pills .show > .nav-link {
    background-color: #1b9ffe;
  }
  .banner-button-2 {
    background-color: #1b9ffe;
    border-radius: 10px;
    color: #ffffff;
    border: 0px;
    padding: 10px 22px;
    border-radius: 25px;
    font-size: 0.85rem;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .banner-text-5 {
    font-size: 14px;
    width: 70%;
  }
  .banner-text-4 {
    margin-top: 20px;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .banner-section-3 .nav-pills {
    background: rgba(27, 159, 254, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    margin: auto;
    width: fit-content;
    padding: 4px;
    border-radius: 50px;
  }
  .banner-section-3 .nav-pills .nav-link {
    font-size: 14px;
    color: #ffffff !important;
    border-radius: 50px;
  }

  .banner-section-2 .card-title {
    font-size: 26px;
  }
  .banner-section-2 .card-text {
    font-size: 13px;
    opacity: 0.8;
    width: 80%;
    margin-bottom: 20px !important;
  }
  .banner-section-2 a {
    color: #1b9ffe !important;
    font-size: 0.9rem;
    font-weight: 800;
  }
  .banner-section-2 .card {
    border: 0px;
    padding-top: 16px;
    padding-bottom: 16px;
    /* border: 1px solid var(--border-color); */
    background: rgba(27, 159, 254, 0);
    transition: transform 0.2s; /* Animation */
  }
  .banner-text-3 {
    font-size: 1.3rem;
    text-align: center;
    width: 50%;
    margin: auto;
  }
  .banner-section-2 {
    padding-top: 10rem;
  }
  .banner-text-2 {
    font-size: 3rem;
    text-align: center;
    text-transform: capitalize;
    font-weight: 700;
  }
  .banner-section-1 .swiper-button-next,
  .banner-section-1 .swiper-button-prev {
    position: absolute;
    top: 86%;
    z-index: 10;
    width: 2.635rem;
    height: 2.625rem;
    border-style: none;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(3.4px);
    backdrop-filter: blur(3.4px);
    color: #fff;
    border-radius: 50%;
  }
  .banner-section-1 .swiper-button-prev,
  .banner-section-1 .swiper-rtl .swiper-button-next {
    left: auto;
    right: 200px;
  }
  .banner-section-1 .swiper-button-next,
  .banner-section-1 .swiper-rtl .swiper-button-prev {
    left: auto;
    right: 120px;
  }
  .banner-section-1 .swiper-button-next:after,
  .banner-section-1 .swiper-button-prev:after {
    font-size: 16px;
  }
  .swiper {
    width: 100%;
    height: 100%;
  }

  .banner-button-icon-1 {
    font-size: 20px;
    margin-left: 4px;
  }

  .banner-text-1 {
    font-size: 2.2rem;
    width: 50%;
    margin-bottom: 30px;
  }

  .wallet-sub-item-section {
    display: none;
  }
  .comingsoon-sub-item-section {
    display: none;
  }
  .DCX-2-sub-item-section {
    display: none;
  }
  .navbar-mega-dropdown {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .dropdown {
    padding: 0.2em !important ;
    border-radius: 4px;
  }
  .dropdown:hover .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-bottom: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-top: 0;
    border-left: 0.3em solid transparent;
  }
  .dropdown:hover .dropdown-menu {
    display: block;
  }
  .mode-change-navbar-button {
    font-size: 18px;
    color: #ffffff !important;
    margin-top: 6px;
  }
  .navbar-build-us-button {
    padding-top: 6px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-top: 10px;
    font-size: 0.8rem;
    height: 34px;
    border: 1px solid #1b9ffe;
    background-color: transparent;
    color: #ffffff !important;
    text-align: center !important;
    border-radius: 14px;
    text-transform: uppercase;
  }
  .navbar-contact-us-button {
    padding-top: 7px;
    font-size: 0.8rem;
    height: 34px;
    padding-left: 0.4vw !important;
    padding-right: 0.4vw !important;
    /* border: 1px solid #1b9ffe; */
    background-color: #1b9ffe;
    color: #ffffff !important;
    text-align: center !important;
    border-radius: 14px;
    text-transform: uppercase;
    box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  }
  .navbar-community-social-links a {
    font-size: 1.2vw;
    color: #fff !important;
    margin-right: 0.6vw;
    opacity: 0.8;
  }
  .navbar-community-social-links {
    margin-bottom: 30px;
  }
  .developer-gif-1 {
    width: 60%;
    height: 60%;
  }
  .deco-icon-1 {
    margin-left: 10px;
  }
  .deco-text-5 {
    color: #ffffff;
    font-size: 12px;
  }
  .deco-text-4 {
    color: #ffffff;
    font-size: 16px;
  }
  .deco-text-3 {
    font-size: 20px;
    margin-bottom: 0px;
    margin-right: 14px;
  }
  .deco-text-2 {
    font-size: 11px;
    margin-bottom: 0px;
    opacity: 0.8;
  }
  .deco-text-1 {
    font-size: 12px;
    margin-bottom: 0px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .dropdown_megamenu-content li {
    margin-bottom: 20px;
    background-color: #21285a8f;

    color: #fff !important;
    border-radius: 8px;
    padding: 15px 10px;
    align-items: center;
  }
  .dropdown_megamenu-content li:hover {
    background-color: #6f7cdb1c;
  }
  .dropdown_megamenu-content .dropdown-item-megadropdown:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #1522857e;
  }
  .navbar-mega-dropdown {
    background-color: transparent;
    border: 0px !important;
    color: #ffffff;
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 0.9rem;
  }
  .dropdown_megamenu-content-2 {
    width: 21rem !important;
  }
  .dropdown_megamenu-content {
    padding: 20px 30px;
    width: 42rem;
    /* height: 32rem; */
    transform-origin: top center;
    animation-name: translateX;
    animation-duration: 0.5s;
    opacity: 1;
    background: #1522857e;
    -webkit-backdrop-filter: blur(63.4px);
    backdrop-filter: blur(63.4px);
  }

  .dropdown-toggle::after {
    margin-left: 0.5em !important;
  }

  .subscription-form {
    max-width: 692px;
    margin: 4px auto;
  }

  .subscription-email {
    width: 100%;
    height: 30%;
    border-radius: 40px;
    font-size: 18px;
    padding: 14px 24px;
    border: none;
    appearance: none;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px, rgba(0, 0, 0, 0.08) 0px 1px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px, rgba(0, 0, 0, 0.02) 0px 8px 50px;
  }

  .subscription-button {
    color: rgb(255, 255, 255);
    border: none;
    width: 100%;
    height: 30%;
    padding: 14px 40px;
    background: rgb(66 66 255);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 1px, rgba(0, 0, 0, 0.1) 0px 1px 4px;
    border-radius: 40px;
    margin-left: 10px;
  }
  .subscription-form .catch-mail {
    width: 767px;
    margin: 4px auto;
    font-size: 14px;
  }

  .blog-section .banner-text-2 {
    text-align: center !important;
  }

  .deco {
    max-width: 483px;
  }

  .wait-button {
    cursor: pointer;
    border: 10px solid transparent;
    background-image: linear-gradient(rgba(19, 20, 25, 0), rgba(19, 20, 25, 0)),
      linear-gradient(
        108.46deg,
        rgba(66, 176, 255, 0.5) 12.51%,
        rgba(103, 109, 255, 0.5) 51.13%,
        rgba(141, 138, 255, 0.5) 87.49%
      );
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    color: rgb(255, 255, 255);
    white-space: nowrap;
    background-color: transparent !important;
    padding: 0px !important;
    height: 42px;
  }

  .product-stay-mail {
    width: 500px;
  }

  .col-lg-7.e-mail-ad {
    margin-left: -11px;
  }
}

/* ============ MOBILE-VIEW ============ */

@media (max-width: 991px) {
  .projects-build-starting-section .card-box img:hover {
    transform: scale(1.5);
  }
  .projects-build-starting-section .card-box img {
    width: 50%;
    margin: auto;
    transition: transform 0.2s; /* Animation */
  }
  .projects-build-starting-section .border-right-0 {
    border-right: 0px solid #ffffff46 !important;
  }
  .projects-build-starting-section .card-box {
    background-color: transparent;
    border-radius: 0px;
    text-align: center;
    /* border: 0px; */
  }

  .projects-build-starting-section .col {
    box-shadow: #00e5ff09 0px 30px 60px -12px inset,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    padding: 20px;
    border: 1px solid #0084ff09;
    border-radius: 4px;
  }
  .projects-build-starting-section .col {
    margin-top: 0px;
  }
  .listet-logo-image-2 {
    z-index: 999;
    width: 50px;
    height: 50px;
    position: relative;
    top: -80px;
    transition: transform 0.2s; /* Animation */
  }
  .listet-logo-image-1 {
    z-index: 999;
    width: 50px;
    height: 50px;
    position: relative;
    top: 80px;
    transition: transform 0.2s; /* Animation */
  }

  .made-by-text-2 {
    font-size: 12px;
    margin-bottom: 0px;
    opacity: 0.8;
  }
  .made-by-text-1 {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 6px;
    letter-spacing: 1px;
  }
  .made-by-decosystem-banner-section .card {
    padding: 20px 10px;
    height: 100%;
    background: rgba(27, 159, 254, 0.1);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    border: 1px solid #00e5ff11;
    border-radius: 12px;
  }

  .sniper-listing-logo {
    height: 55px;
    width: 90% !important;
    margin: auto;
  }
  .Partneradvisor-listing-banner-section .card {
    text-align: center;
    border: none;
    background: #031228;
    padding: 30px 30px;
    height: 100%;
  }
  .Partneradvisor-listing-banner-section .card img {
    width: 22%;
    height: auto;
  }

  .Partner-listing-banner-section .card img {
    height: 55px;
    width: 80%;
    margin: auto;
    transition: transform 0.8s; /* Animation */
  }
  .Partner-listing-banner-section .card {
    text-align: center;
    border: none;
    background: #031228;
    padding: 10px 10px;
    height: 100%;
    transition: transform 0.8s; /* Animation */
  }
  .Partner-listing-banner-section .card:hover img {
    transform: scale(
      1.2
    ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  .container-glow .btn-kyc-banner {
    position: relative;
    width: 125px;
    height: 40px;
    margin-right: 20px;
  }
  .popup-text-1 {
    font-size: 1.6rem;
    text-transform: uppercase;
    margin-bottom: 0px;
    font-weight: 800;
    line-height: 1.6rem;
    animation-name: fadefromtop;
    animation-duration: 0.8s;
    animation-delay: 0.9s;
    animation-fill-mode: both;
  }
  .popup-text-2 {
    font-size: 4rem;
    text-transform: uppercase;
    margin-bottom: 0px;
    font-weight: 1000;
    line-height: 5rem;
    animation-name: fadefromleft;
    animation-duration: 0.8s;
    animation-delay: 0.9s;
    animation-fill-mode: both;
  }
  .popup-text-3 {
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-weight: 1000;
    margin-bottom: 2.5rem;
    animation-name: fadefromleft;
    animation-duration: 1.1s;
    animation-delay: 1.2s;
    animation-fill-mode: both;
  }
  .stepImge-text-2 {
    font-size: 0.8rem;
  }
  .stepImge-text-1 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 10px;
    /* margin-top: 40px; */
  }
  .stepImg-tabs img {
    width: 37px;
    height: 37px;
    object-fit: contain;
  }
  .stepImg-tabs {
    margin-top: 40px !important;
    width: 81px;
    height: 81px;
    box-shadow: 0 16px 24px rgb(74 144 226 / 10%);
    border-radius: 100%;
    background: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 40px !important;
  }

  .build-flip-image-section:hover .build-flip-image-1 {
    transform: skew(0deg, 0deg);
  }
  .build-flip-image-section:hover .build-flip-image-2 {
    transform: skew(0deg, 0deg);
    position: relative;
    top: 10px;
  }
  .build-flip-image-1 {
    width: 60%;
    transform: skew(-15deg, 0deg);
    z-index: 999;
    transition: 0.6s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  .build-flip-image-2 {
    width: 60%;
    transform: skew(-15deg, 0deg);
    position: relative;
    top: -50px;
    z-index: -1;
    transition: 0.6s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  .banner-section-2-2 .card img {
    width: 40px;
    height: 40px;
  }
  .banner-section-2-2 a {
    font-size: 0.8rem;
  }
  .banner-section-2-2 .card-text {
    font-size: 0.8rem;
    margin-bottom: 4px;
  }
  .banner-section-2-2 .card-title {
    font-size: 1.4rem;
    font-weight: 900;
    letter-spacing: 1px;
  }
  .banner-section-2-2 .card {
    background: transparent;
    border: none !important;
  }
  .manualconnect-modal-section {
    background: rgba(27, 159, 254, 0.1);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }
  .manualconnect-modal-section .modal-content {
    background: rgba(27, 159, 254, 0.1);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border: 1px solid #00e5ff21;
    border-radius: 12px;
  }
  .blogreadmore-aditional-image-1 {
    width: 90px;
    height: auto;
  }
  .blogreadmore-aditional-text-1 {
    font-size: 12px;
    margin-bottom: 0px;
  }
  .blogreadmore-aditional-section .card {
    padding: 20px;
    margin-bottom: 30px;
    border: 1px solid #cecece13;
    border-radius: 8px;
    background: rgba(27, 159, 254, 0.034);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }
  .idolive-button-image {
    height: 107px;
    /* position: absolute;
    top: -100px; */
    width: 300px;
  }
  #piediv {
    font-size: 12px;
  }
  .banner-top-fixed-timer-rool-image {
    width: 16vh;
    height: 16vh;
    position: fixed;
    top: calc(100vh - 24vh);
    left: 0px;
    z-index: 999;
  }
  .banner-video-bottom-1 {
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    object-fit: cover;
    z-index: -9;
    opacity: 0.4;
  }
  .roadmap-button-1 {
    position: relative;
    /* right: -100px; */
    background: rgba(27, 159, 254, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px;
    color: #ffffff;
    border: 0px;
    padding: 8px 22px;
    border-radius: 25px;
    font-size: 0.85rem;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .d-chain-pos-banner-section .accordion-button::after {
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: 12px;
    transition: var(--bs-accordion-btn-icon-transition);
  }

  .d-chain-scroll-container {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
  .d-chain-scroll-container li {
    font-size: 0.9rem;
  }
  .d-chain-pos-banner-section .nav-link {
    color: #fff !important;
  }
  .d-chain-pos-banner-section .sidebar-left a {
    font-size: 14px;
    text-transform: capitalize;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-weight: 600;
  }
  .d-chain-scroll-container h5 {
    font-size: 1.1rem;
    font-weight: 600;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
  .d-chain-scroll-container h4 {
    font-size: 1.5rem;
    font-weight: 600;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    margin-top: 100px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  .d-chain-scroll-container p {
    font-size: 0.9rem;
    font-weight: 400;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    margin-bottom: 30px;
  }
  .dchain-text-1 {
    font-size: 18px;
  }

  .d-chain-pos-banner-section .accordion-button:not(.collapsed) {
    color: #fff;
    background-color: transparent;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
      var(--bs-accordion-border-color);
  }
  .d-chain-pos-banner-section .accordion {
    --bs-accordion-btn-active-icon: url("../images/banner/arrow.svg");
    --bs-accordion-btn-icon: url("../images/banner/arrow-right.svg");
    box-shadow: none !important;
  }
  .d-chain-pos-banner-section .accordion-item {
    color: var(--bs-accordion-color);
    background-color: transparent;
    border: none !important;
    box-shadow: none !important;
  }
  .d-chain-pos-banner-section .accordion-button {
    box-shadow: none !important;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 10px;
    font-size: 1rem;
    color: #fff;
    text-align: left;
    background-color: #000210;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    border-color: #000210;
    transition: var(--bs-accordion-transition);
  }

  .mediakit-top-section .dropdown-menu {
    right: 10px;
    --bs-dropdown-min-width: 8rem;
  }
  .mediakit-top-section .card {
    padding: 10px;
    background: rgba(27, 159, 254, 0.041);
    backdrop-filter: blur(20px);
    color: #fff;
    z-index: 1;
  }
  .Error404-image-home-button {
    position: relative;
    top: -80px;
    width: 140px;
    height: 30px;
    margin: auto;
  }
  .Error404-image {
    width: 90%;
    margin: auto;
  }
  .event-banner-section .card {
    background-color: transparent;
    border: 1px solid #dee2e627;
    padding: 50px 10px;
    font-size: 14px;
  }
  .event-banner-section p {
    margin-bottom: 6px;
  }
  .event-banner-section .swiper {
    padding-bottom: 100px;
    width: 90%;
    margin-top: 50px;
  }
  .event-banner-section .swiper-button-prev,
  .event-banner-section .swiper-rtl .swiper-button-next {
    left: auto;
    right: 80px;
  }
  .event-banner-section .swiper-button-next,
  .event-banner-section .swiper-rtl .swiper-button-prev {
    left: auto;
    right: 20px;
  }
  .event-banner-section .swiper-button-next:after,
  .event-banner-section .swiper-button-prev:after {
    font-size: 16px;
  }
  .event-banner-section .swiper-button-next,
  .event-banner-section .swiper-button-prev {
    position: absolute;
    top: 86%;
    z-index: 10;
    width: 2.635rem;
    height: 2.625rem;
    border-style: none;
    background: rgba(27, 159, 254, 0.1);
    backdrop-filter: blur(20px);
    backdrop-filter: blur(3.4px);
    color: #fff;
    border-radius: 50%;
  }

  .join-event-button {
    background: rgba(27, 159, 254, 0.986);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px;
    color: #ffffff;
    border: 0px;
    padding: 8px 22px;
    border-radius: 25px;
    font-size: 0.85rem;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .event-banner-section .nav-pills .nav-link.active,
  .event-banner-section .nav-pills .show > .nav-link {
    background-color: #1b9ffe;
  }
  .event-banner-section .nav-pills {
    background: rgba(27, 159, 254, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    margin: auto;
    width: fit-content;
    border-radius: 50px;
  }
  .event-banner-section .nav-pills .nav-link {
    font-size: 12px;
    letter-spacing: 0.5px;
    color: #ffffff !important;
    text-transform: capitalize;
    border-radius: 50px;
  }
  .event-banner-section {
    padding: 20px 10px;
    border: 1px solid #cecece09;
    border-radius: 8px;
    background: rgba(27, 159, 254, 0.021);
    border-radius: 12px;
  }
  .internal-page-padding-top {
    padding-top: 10rem;
  }
  .footer-typo-icons {
    color: #ffffff !important;
    position: relative;
    left: -6px;
    opacity: 0;
    transition: 0.3s;
  }
  .footer-typo a {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #67666e !important;
    transition: color 0.2s;
  }
  .footer-typo a:hover .footer-typo-icons {
    left: 2px;
    opacity: 1;
  }
  .footer-typo a:hover {
    color: #ffffff !important;
  }
  .footer-text-1 {
    font-size: 1rem;
    margin-bottom: 1.05rem;
  }
  .navbar-brand-footer {
    width: 150px;
    height: 70px;
  }
  .footer-social-icons-link a {
    cursor: pointer;
    margin-left: 1.4rem;
    font-size: 1.3rem;
    transition: color 0.2s;
    color: #67666e !important;
  }
  .footer-social-icons-link a:hover {
    color: #ffffff !important;
  }
  .banner-section-8 .input-group {
    background: rgba(27, 159, 254, 0.1);
    -webkit-backdrop-filter: blur(60px);
    backdrop-filter: blur(60px);
    border: 1px solid #2d2c3359;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
  }
  .banner-section-8 .input-group:hover {
    border: 1px solid #1b9ffe6c;
  }
  .banner-section-8 .input-group-text {
    margin-left: 0px !important;
    box-shadow: none !important;
    min-height: 3.75rem;
    padding: 1.25rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    background: rgba(32, 88, 128, 0.1);
    border: 0px solid #2d2c33 !important;
    border-radius: 0.375rem;
  }
  .banner-section-8 ::placeholder {
    color: #ffffff !important;
    font-size: 15px;
  }
  .banner-section-8 .form-control {
    box-shadow: none !important;
    min-height: 3rem;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    color: #fff;
    background: rgba(32, 88, 128, 0.1);
    border: 0px solid #2d2c33;
  }
  .banner-section-8 .form-control:focus {
    color: #fff;
    background: rgba(32, 88, 128, 0.1);
    border: 0px solid #2d2c33;
  }
  .stay-mail-text-2 {
    font-size: 2rem;
  }
  .stay-mail-text-1 {
    font-size: 10px;
    letter-spacing: 2px;
    color: #1b9ffe;
  }
  .banner-section-7 .card-text {
    margin-top: 30px;
    font-size: 1.25rem;
    line-height: 1.6;
    font-weight: 400;
  }
  .blog-text-1 {
    font-size: 10px;
    text-transform: uppercase;
  }
  .blog-text-2 {
    font-size: 10px;
    color: #1b9ffe;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .banner-section-6-glow-1 {
    position: absolute;
    width: 300px;
    height: 300px;
    /* top: -60px;
    left: -60px; */
  }
  .banner-section-6-glow-2 {
    position: absolute;
    width: 300px;
    height: 300px;
    bottom: -60px;
    left: 75%;
  }
  .banner-section-7 .card-body {
    padding: 30px 30px;
  }
  .banner-section-7 .card {
    border: 1px solid #cecece13;
    border-radius: 20px;
    background: rgba(32, 88, 128, 0.1);
    cursor: pointer;
    transition: 0.2s; /* Animation */
  }
  .banner-section-7 .card-body {
    transition: 0.2s; /* Animation */
  }

  .banner-section-7 .card:hover {
    transform: scale(
      1.01
    ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .banner-content {
    position: absolute;
    top: 4%;
    left: 80%;
    display: none;
    font-size: 40px;
  }
  .banner-section-7 .card:hover .banner-content {
    display: block;
  }
  .banner-section-7 .card:hover {
    background-color: #1b9ffe15;
  }
  .banner-section-7 .card:hover .card-body {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .banner-section-7 .card img {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    width: 100%;
  }
  .banner-section-7 .swiper-button-prev,
  .banner-section-7 .swiper-rtl .swiper-button-next {
    left: auto;
    right: 200px;
  }
  .banner-section-7 .swiper-button-next,
  .banner-section-7 .swiper-rtl .swiper-button-prev {
    left: auto;
    right: 120px;
  }
  .banner-section-7 .swiper-button-next:after,
  .banner-section-7 .swiper-button-prev:after {
    font-size: 16px;
  }
  .banner-section-7 .swiper-button-next,
  .banner-section-7 .swiper-button-prev {
    position: absolute;
    top: 86%;
    z-index: 10;
    width: 2.635rem;
    height: 2.625rem;
    border-style: none;
    background: rgba(27, 159, 254, 0.1);
    -webkit-backdrop-filter: blur(60px);
    backdrop-filter: blur(60px);
    border-radius: 10px;
    color: #fff;
    border-radius: 50%;
  }
  .banner-section-7 .swiper {
    padding-bottom: 200px;
  }

  .banner-section-6-text-2 {
    color: #b1b1b1;
    font-size: 1.1rem;
    line-height: 1.4;
    font-weight: 500;
  }
  .banner-section-6-text-1 {
    font-size: 2.45rem;
    line-height: 1.2;
    font-weight: 500;
  }
  .banner-section-6 .card {
    background: rgba(27, 159, 254, 0.1);
    -webkit-backdrop-filter: blur(60px);
    backdrop-filter: blur(60px);
    border-radius: 10px;
    border: 1px solid #00e5ff17;
    border-radius: 12px;
    padding-top: 80px;
    transition: 0.2s; /* Animation */
  }

  .banner-section-6 {
    background: rgba(0, 229, 255, 0.008);
    padding-bottom: 40px !important;
    /* background-image: url("../images/banner/Vector\ Smart\ Object.png");
    background-size: 100% 100%; */
    background-repeat: no-repeat;
  }
  .banner-section-2-icon-1 {
    font-size: 6rem;
    padding: 20px;
    margin-bottom: 30px;
    border: 1px solid #cecece13;
    border-radius: 8px;
    background: rgba(27, 159, 254, 0.1);
    backdrop-filter: blur(20px);
  }
  .banner-section-2 .card:hover .banner-section-2-icon-1 {
    animation: animName 3s linear infinite;
  }
  @keyframes animName {
    0% {
      transform: rotateY(0deg);
    }
    100% {
      transform: rotateY(360deg);
    }
  }
  .glow-effects-circle-image-1 {
    width: 200px;
    height: 200px;
    position: absolute;
    right: 14%;
    top: 1%;
  }
  .banner-section-3 .nav-pills .nav-link.active,
  .banner-section-3 .nav-pills .show > .nav-link {
    background-color: #1b9ffe;
  }
  .banner-button-2 {
    background-color: #1b9ffe;
    border-radius: 10px;
    color: #ffffff;
    border: 0px;
    padding: 10px 22px;
    border-radius: 25px;
    font-size: 0.85rem;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .banner-text-5 {
    font-size: 14px;
  }
  .banner-text-4 {
    margin-top: 20px;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .banner-section-3 .nav-pills {
    background: rgba(27, 159, 254, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    margin: auto;
    width: fit-content;
    padding: 4px;
    border-radius: 50px;
  }
  .banner-section-3 .nav-pills .nav-link {
    font-size: 12px;
    color: #ffffff !important;
    border-radius: 50px;
  }

  .banner-section-2 .card-title {
    font-size: 26px;
  }
  .banner-section-2 .card-text {
    font-size: 13px;
    opacity: 0.8;
    width: 80%;
    margin-bottom: 20px !important;
  }
  .banner-section-2 a {
    color: #1b9ffe !important;
    font-size: 0.9rem;
    font-weight: 800;
  }
  .banner-section-2 .card {
    border: 0px;
    padding-top: 16px;
    padding-bottom: 16px;
    /* border: 1px solid var(--border-color); */
    background: rgba(27, 159, 254, 0);
    transition: transform 0.2s; /* Animation */
  }
  .banner-text-3 {
    font-size: 1rem;
    text-align: center;
    margin: auto;
  }
  .banner-section-2 {
    padding-top: 3rem;
  }
  .banner-text-2 {
    font-size: 1.5rem;
    text-align: center;
    text-transform: capitalize;
    font-weight: 700;
  }
  .banner-section-1 .swiper-button-next,
  .banner-section-1 .swiper-button-prev {
    position: absolute;
    top: 90%;
    z-index: 10;
    width: 1.935rem;
    height: 1.925rem;
    border-style: none;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(3.4px);
    backdrop-filter: blur(3.4px);
    color: #fff;
    border-radius: 50%;
  }
  .banner-section-1 .swiper-button-prev,
  .banner-section-1 .swiper-rtl .swiper-button-next {
    left: auto;
    right: 100px;
  }
  .banner-section-1 .swiper-button-next,
  .banner-section-1 .swiper-rtl .swiper-button-prev {
    left: auto;
    right: 40px;
  }
  .banner-section-1 .swiper-button-next:after,
  .banner-section-1 .swiper-button-prev:after {
    font-size: 14px;
  }
  .swiper {
    width: 100%;
    height: 100%;
  }

  .banner-button-icon-1 {
    font-size: 20px;
    margin-left: 4px;
  }
  .banner-button-1 {
    cursor: pointer;
    border: 1px solid transparent;
    background-image: linear-gradient(rgba(19, 20, 25, 0), rgba(19, 20, 25, 0)),
      linear-gradient(
        108.46deg,
        rgba(66, 176, 255, 0.5) 12.51%,
        rgba(103, 109, 255, 0.5) 51.13%,
        rgba(141, 138, 255, 0.5) 87.49%
      );
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    color: rgb(255, 255, 255);
    white-space: nowrap;
    background-color: transparent !important;
    padding: 0px !important;
  }
  .banner-button-1 > div {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64)),
      linear-gradient(
        108.46deg,
        rgba(66, 176, 255, 0) 12.51%,
        rgba(103, 108, 255, 0) 51.13%,
        rgba(142, 138, 255, 0) 87.49%
      );
    border-radius: 8px;
    padding: 10px 24px;
  }

  .banner-section-top-padding {
    position: relative;
    top: 50vh;
    z-index: 999;
  }
  .banner-text-1 {
    font-size: 1.8rem;
    margin-bottom: 30px;
  }
  .banner-video-1 {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    object-fit: cover;
    z-index: -9;
    /* opacity: 0.2; */
  }
  .wallet-sub-item-section {
    display: none;
  }
  .comingsoon-sub-item-section {
    display: none;
  }
  .DCX-2-sub-item-section {
    display: none;
  }
  .navbar-mega-dropdown {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .mode-change-navbar-button {
    font-size: 20px;
    color: #ffffff !important;
    margin-top: 5px;
  }
  .navbar-build-us-button {
    padding-top: 6px;
    margin-top: 10px;
    font-size: 0.8rem;
    height: 34px;
    border: 1px solid #1b9ffe;
    background-color: transparent;
    color: #ffffff !important;
    text-align: center !important;
    border-radius: 14px;
    text-transform: uppercase;
  }
  .navbar-contact-us-button {
    padding-top: 6px;
    font-size: 0.8rem;
    height: 34px;
    border: 1px solid #1b9ffe;
    background-color: #1b9ffe;
    color: #ffffff !important;
    text-align: center !important;
    border-radius: 14px;
    text-transform: uppercase;
    box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  }
  .navbar-community-social-links a {
    font-size: 22px;
    color: #fff !important;
    margin-right: 10px;
    opacity: 0.8;
  }
  .navbar-community-social-links {
    margin-bottom: 30px;
  }
  .developer-gif-1 {
    width: 60%;
    height: 60%;
  }
  .deco-icon-1 {
    margin-left: 10px;
  }
  .deco-text-5 {
    color: #ffffff;
    font-size: 12px;
  }
  .deco-text-4 {
    color: #ffffff;
    font-size: 16px;
  }
  .deco-text-3 {
    font-size: 20px;
    margin-bottom: 0px;
    margin-right: 14px;
  }
  .deco-text-2 {
    font-size: 9px;
    margin-bottom: 0px;
    opacity: 0.8;
  }
  .deco-text-1 {
    font-size: 11px;
    margin-bottom: 0px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .dropdown_megamenu-content li {
    margin-bottom: 10px;
    border: 1px solid #cecece3b;
    border-radius: 8px;
    padding: 6px 6px;
    align-items: center;
  }

  .navbar-mega-dropdown {
    background-color: transparent;
    border: 0px !important;
    color: #ffffff;
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 1rem;
  }
  .dropdown_megamenu-content {
    padding: 8px 10px;
    border: 0px;
    background-color: #080d35;
  }
  .dropdown_megamenu-content li {
    margin-bottom: 20px;
    background-color: #21285a8f;

    color: #fff !important;
    border-radius: 8px;
    padding: 15px 10px;
    align-items: center;
  }
  .dropdown_megamenu-content li:hover {
    background-color: #6f7cdb1c;
  }
  .dropdown_megamenu-content .dropdown-item-megadropdown:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #1522857e;
  }

  .dropdown-toggle::after {
    margin-left: 0.5em !important;
  }
  .dropdown_megamenu-content {
    width: 100%;
    height: auto;
  }
  .dropdown-toggle::after {
    margin-left: auto !important;
  }
  .subscription-form {
    max-width: 692px;
    margin: 4px auto;
  }

  .subscription-email {
    width: 100%;
    height: 30%;
    border-radius: 40px;
    font-size: 18px;
    padding: 14px 24px;
    border: none;
    appearance: none;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px, rgba(0, 0, 0, 0.08) 0px 1px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px, rgba(0, 0, 0, 0.02) 0px 8px 50px;
  }

  .subscription-button {
    color: rgb(255, 255, 255);
    border: none;
    width: 100%;
    height: 30%;
    padding: 14px 40px;
    background: rgb(66 66 255);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 1px, rgba(0, 0, 0, 0.1) 0px 1px 4px;
    border-radius: 40px;
    margin-left: 10px;
  }
  .subscription-form .catch-mail {
    width: 767px;
    margin: 4px auto;
    font-size: 14px;
  }

  .blog-section .banner-text-2 {
    text-align: center !important;
  }

  .deco {
    max-width: 483px;
  }

  .wait-button {
    cursor: pointer;
    border: 10px solid transparent;
    background-image: linear-gradient(rgba(19, 20, 25, 0), rgba(19, 20, 25, 0)),
      linear-gradient(
        108.46deg,
        rgba(66, 176, 255, 0.5) 12.51%,
        rgba(103, 109, 255, 0.5) 51.13%,
        rgba(141, 138, 255, 0.5) 87.49%
      );
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    color: rgb(255, 255, 255);
    white-space: nowrap;
    background-color: transparent !important;
    padding: 0px !important;
    height: 42px;
  }
  .product-stay-mail {
    width: 500px;
  }
  .new-hackathon-banner-section-2 .card{
    background-color: #222d40;
    color: #FFF;
    text-align: center;
  }
}
#piediv tspan {
  color: #fff !important;
  fill: #fff !important;
}
#piediv polyline {
  color: #fff !important;
  fill: #fff !important;
}
#piediv polyline {
  color: #fff !important;
  stroke: #fff !important;
}

header {
  padding: 15px;
  background: #20232a;
  color: #dfdfdf;
  text-align: center;
}
header span,
header .logo > * {
  color: #41e0fd;
}
header.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
}
header.navigation a {
  color: #c5c5c5;
  text-decoration: none;
  margin: 0 5px;
}
header.navigation a.logo {
  margin: 0;
  padding: 0;
  font-size: 1.5em;
  line-height: 1;
  margin-right: 10px;
}

main section {
  width: 85%;
  margin: auto;
}
main aside {
  padding-left: 20px;
  padding-right: 20px;
}
main aside > ol,
main aside > ul {
  position: sticky;
  top: 80px;
}
main aside > ol a,
main aside > ul a {
  text-decoration: none;
  display: inline-block;
}
main aside > ol a:hover,
main aside > ul a:hover {
  color: #5f5f5f;
}
main aside ul,
main aside ol {
  list-style: none;
}
main aside ul li > ul {
  padding-left: 10px;
}

.blogread-icon-1 {
  font-size: 1.5rem;
}

.blog-read-more-section aside {
  padding-left: 20px;
  padding-right: 20px;
}
.blog-read-more-section aside > ol,
.blog-read-more-section aside > ul {
  position: sticky;
  top: 80px;
}
.blog-read-more-section aside > ol a,
.blog-read-more-section aside > ul a {
  text-decoration: none;
  display: inline-block;
}
.blog-read-more-section aside > ol a:hover,
.blog-read-more-section aside > ul a:hover {
  color: #5f5f5f;
}
.blog-read-more-section aside ul,
.blog-read-more-section aside ol {
  list-style: none;
}
.blog-read-more-section aside ul li > ul {
  padding-left: 10px;
}

.roadmap-arrow-tabs {
  width: 60px !important;
  height: 60px;
}

.PhoneInput {
  display: flex;
}
.PhoneInputCountrySelect {
  width: 80px;
  background: transparent;
  color: #fff;
  outline: none !important;
  border: none !important;
  margin-right: 6px;
  margin-left: 20px;
}
.PhoneInputCountrySelect option {
  color: #000;
}
.PhoneInputCountryIconImg {
  width: 40px;
}
.PhoneInputCountry {
  display: flex;
}
.PhoneInputInput {
  box-shadow: none !important;
  min-height: 3.75rem;
  padding: 1.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background: transparent;
  border: 0px solid #2d2c33;
  width: max-content;
  outline: none !important;
}
.arrow-tabs-left {
  cursor: pointer;
}
.arrow-tabs-right {
  cursor: pointer;
}
.myBubbleUI {
  width: 100%;
  max-width: 500px;
  height: 350px;
  background-color: blueviolet;
}

.interestBubbleUI {
  width: 100%;
  max-width: 500px;
  height: 350px;
  background-color: tomato;
}

.childComponent {
  width: 100%; /* width expands to fit bubble */
  height: 100%; /* width expands to fit bubble */
  border-radius: 50%; /* rounded border forms a circle */
  background-color: burlywood;
  display: flex;
  align-items: center;
  justify-content: center;
}

.highcharts-background {
  fill: #000210;
}

.highcharts-no-tooltip {
  display: none !important;
}
.highcharts-credits {
  display: none !important;
}

.banner-section-3 path:hover {
  fill: #1b9ffe;
}
.dao-text-1 {
  font-size: 18px;
  margin-bottom: 8px !important;
}
.dao-text-2 {
  margin-bottom: 0px;
}
.dao-text-3-margin {
  margin-top: 6px !important;
}
.dao-text-3 {
  font-weight: 800;
  margin-bottom: 0px;
  text-transform: capitalize !important;
}
.banner-section-3 text {
  font-size: 14px !important;
}
.highcharts-tooltip-box {
  fill: #ffffff;
}
.highcharts-tooltip span:first-child {
  top: 0px !important;
}
.highcharts-tooltip span:first-child > span {
  display: none !important;
}
#launchedmodal {
  /* overflow: hidden;
  --bs-modal-zindex: 1055; */
  /* --bs-modal-width: 36vw !important; */
  /* pointer-events: none;
  border: 0px; */
}
#launchedmodal .modal-content {
  border: none !important;
  background-color: transparent !important;
  padding: 20px;
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

#btn-close-announce {
  /* position: relative;
  top: 6vw; */
  z-index: 999;
  background-size: 1em;
  padding: 10px;
  border: 1px solid #000000;
  border-radius: 50%;
  /* right: 2vw; */
  animation-name: fadefromleft;
  animation-duration: 1.1s;
  animation-delay: 1.2s;
  animation-fill-mode: both;
}
.Protocol-video-section {
  width: 100%;
  height: 100%;
}

.fc-b {
  color: #1b9ffe;
}
.navbar-contact-popup-button {
  padding: 8px 20px;
  font-size: 0.9rem;
  width: fit-content;
  /* border: 1px solid #1b9ffe; */
  background-color: #1b9ffe;
  color: #ffffff !important;
  text-align: center !important;
  border-radius: 14px;
  text-transform: uppercase;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  margin: auto;
  animation-name: fadefromleft;
  animation-duration: 1.1s;
  animation-delay: 1.2s;
  animation-fill-mode: both;
}

/* Animations */
@keyframes fadefromtop {
  0% {
    opacity: 0;
    transform: translateY(-150px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes fadefromleft {
  0% {
    opacity: 0;
    transform: translateX(150px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes bang {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.hoverme {
  margin: auto;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
  position: relative;
  top: -0px;
}

.hoverme i {
  position: absolute;
  display: block;
  left: 50%;
  top: 0;
  width: 24px;
  height: 24px;
  opacity: 0;
}
.hoverme i:nth-of-type(1) {
  transform: translate3d(83px, -91px, 0) rotate(61deg);
  background: #ffc800;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(2) {
  transform: translate3d(76px, -84px, 0) rotate(282deg);
  background: #0048ff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(3) {
  transform: translate3d(-100px, -92px, 0) rotate(234deg);
  background: #00ff55;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(4) {
  transform: translate3d(64px, -96px, 0) rotate(301deg);
  background: #00f7ff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(5) {
  transform: translate3d(89px, -110px, 0) rotate(295deg);
  background: #37ff00;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(6) {
  transform: translate3d(90px, -94px, 0) rotate(257deg);
  background: #80ff00;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(7) {
  transform: translate3d(-133px, -120px, 0) rotate(346deg);
  background: #91ff00;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(8) {
  transform: translate3d(-72px, -91px, 0) rotate(348deg);
  background: #80ff00;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(9) {
  transform: translate3d(-86px, -96px, 0) rotate(211deg);
  background: #ffcc00;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(10) {
  transform: translate3d(90px, -120px, 0) rotate(117deg);
  background: #00ff6a;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(11) {
  transform: translate3d(98px, -82px, 0) rotate(303deg);
  background: #ffa600;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(12) {
  transform: translate3d(148px, -95px, 0) rotate(354deg);
  background: #f7ff00;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(13) {
  transform: translate3d(-86px, -91px, 0) rotate(252deg);
  background: #0040ff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(14) {
  transform: translate3d(-60px, -84px, 0) rotate(340deg);
  background: #00ffee;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(15) {
  transform: translate3d(-12px, -80px, 0) rotate(338deg);
  background: #51ff00;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(16) {
  transform: translate3d(33px, -84px, 0) rotate(106deg);
  background: #ff00dd;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(17) {
  transform: translate3d(-74px, -73px, 0) rotate(123deg);
  background: #bf00ff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(18) {
  transform: translate3d(23px, -93px, 0) rotate(169deg);
  background: #4400ff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(19) {
  transform: translate3d(69px, -76px, 0) rotate(200deg);
  background: #a600ff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(20) {
  transform: translate3d(-88px, -175px, 0) rotate(161deg);
  background: #d500ff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(21) {
  transform: translate3d(-10px, -93px, 0) rotate(57deg);
  background: #000dff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(22) {
  transform: translate3d(-38px, -177px, 0) rotate(335deg);
  background: #bf00ff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(23) {
  transform: translate3d(-38px, -181px, 0) rotate(353deg);
  background: #2f00ff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(24) {
  transform: translate3d(-56px, -185px, 0) rotate(284deg);
  background: #ff5100;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(25) {
  transform: translate3d(54px, -179px, 0) rotate(140deg);
  background: #ff0033;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(26) {
  transform: translate3d(189px, -191px, 0) rotate(195deg);
  background: #a200ff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(27) {
  transform: translate3d(-159px, -150px, 0) rotate(173deg);
  background: #00ff80;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(28) {
  transform: translate3d(-173px, -201px, 0) rotate(179deg);
  background: #3700ff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(29) {
  transform: translate3d(-147px, -172px, 0) rotate(79deg);
  background: #00ff4d;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(30) {
  transform: translate3d(129px, -174px, 0) rotate(57deg);
  background: #0084ff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(31) {
  transform: translate3d(145px, -157px, 0) rotate(73deg);
  background: #ff00d5;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(32) {
  transform: translate3d(95px, -171px, 0) rotate(352deg);
  background: #ff00ae;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(33) {
  transform: translate3d(-114px, -128px, 0) rotate(20deg);
  background: #0011ff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(34) {
  transform: translate3d(179px, -156px, 0) rotate(346deg);
  background: #00ff48;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(35) {
  transform: translate3d(-90px, -153px, 0) rotate(285deg);
  background: #ffc800;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(36) {
  transform: translate3d(189px, -155px, 0) rotate(277deg);
  background: #91ff00;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(37) {
  transform: translate3d(176px, -163px, 0) rotate(76deg);
  background: #f7ff00;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(38) {
  transform: translate3d(144px, -141px, 0) rotate(178deg);
  background: #0011ff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(39) {
  transform: translate3d(87px, -132px, 0) rotate(211deg);
  background: lime;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(40) {
  transform: translate3d(81px, -177px, 0) rotate(87deg);
  background: #8400ff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(41) {
  transform: translate3d(-146px, -197px, 0) rotate(140deg);
  background: yellow;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(42) {
  transform: translate3d(134px, -190px, 0) rotate(137deg);
  background: #004dff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(43) {
  transform: translate3d(-35px, -172px, 0) rotate(134deg);
  background: #0066ff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(44) {
  transform: translate3d(-138px, -189px, 0) rotate(26deg);
  background: #6aff00;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(45) {
  transform: translate3d(-35px, -133px, 0) rotate(110deg);
  background: #00ffea;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(46) {
  transform: translate3d(16px, -185px, 0) rotate(82deg);
  background: #ff008c;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(47) {
  transform: translate3d(48px, -190px, 0) rotate(44deg);
  background: #00ff59;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(48) {
  transform: translate3d(15px, -198px, 0) rotate(335deg);
  background: #00ffc4;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(49) {
  transform: translate3d(29px, -229px, 0) rotate(130deg);
  background: #bf00ff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme i:nth-of-type(50) {
  transform: translate3d(64px, -234px, 0) rotate(179deg);
  background: #00ffea;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}

.container-glow .btn-kyc-banner a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  color: #fff !;
  z-index: 1;
  font-weight: 400;
  letter-spacing: 1px;
  text-decoration: none;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(15px);
  font-size: 18px;
}
.container-glow .btn-kyc-banner:hover a {
  letter-spacing: 3px;
}
.container-glow .btn-kyc-banner a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
  transition: 0.5s;
  transform: skew(45deg) translate(0);
}
.container-glow .btn-kyc-banner:hover a::before {
  transform: skew(45deg) translate(200%);
}
.container-glow .btn-kyc-banner::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -5px;
  width: 30px;
  height: 10px;
  background: #f00;
  border-radius: 10px;
  transition: 0.5s;
  transition-delay: 0s;
}
.container-glow .btn-kyc-banner:hover::before {
  height: 50%;
  width: 80%;
  bottom: 0;
  border-radius: 30px;
  transition-delay: 0.15s;
}
.container-glow .btn-kyc-banner::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -5px;
  width: 30px;
  height: 10px;
  background: #f00;
  border-radius: 10px;
  transition: 0.5s;
  transition-delay: 0s;
}
.container-glow .btn-kyc-banner:hover::after {
  height: 50%;
  width: 80%;
  top: 0;
  border-radius: 30px;
  transition-delay: 0.15s;
}
.container-glow .btn-kyc-banner:nth-child(1)::before,
.container-glow .btn-kyc-banner:nth-child(1)::after {
  background: #2bd2ff;
  box-shadow: 0 0 5px #2bd2ff, 0 0 15px #2bd2ff, 0 0 30px #2bd2ff,
    0 0 60px #2bd2ff;
}

.clr:after {
  content: "";
  display: block;
  clear: both;
}
#categories li {
  position: relative;
  list-style-type: none;
  float: left;
  overflow: hidden;
  visibility: hidden;
  transform: rotate(-60deg) skewY(30deg);
}
#categories li * {
  position: absolute;
  visibility: visible;
}
#categories li > div {
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  overflow: hidden;
  transform: skewY(-30deg) rotate(60deg);
  backface-visibility: hidden;
}
/* HEX CONTENT */
#categories li img {
  left: -100%;
  right: -100%;
  width: auto;
  height: 100%;
  margin: 0 auto;
  transition: transform 0.2s; /* Animation */
}
#categories li:hover img {
  transform: scale(
    1.1
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

@media (min-width: 1201px) {
  #categories li {
    width: 17.364%; /* = (100-4.5) / 5.5 */
    padding-bottom: 20.05%; /* =  width /0.866 */
  }
  #categories li:nth-child(10n + 6),
  #categories li:nth-child(10n + 7),
  #categories li:nth-child(10n + 8),
  #categories li:nth-child(10n + 9),
  #categories li:nth-child(10n + 10) {
    margin-top: -4.2%;
    margin-bottom: -4.2%;
    transform: translateX(50%) rotate(-60deg) skewY(30deg);
  }
  #categories li:nth-child(10n + 6):last-child,
  #categories li:nth-child(10n + 7):last-child,
  #categories li:nth-child(10n + 8):last-child,
  #categories li:nth-child(10n + 9):last-child,
  #categories li:nth-child(10n + 10):last-child {
    margin-bottom: 0;
  }
  #categories li:nth-child(10n + 6) {
    margin-left: 0.5%;
  }
  #categories li:nth-child(5n + 2) {
    margin-left: 1%;
    margin-right: 1%;
  }
  #categories li:nth-child(5n + 3),
  #categories li:nth-child(5n + 4) {
    margin-right: 1%;
  }
  .subscription-form {
    max-width: 692px;
    margin: 4px auto;
  }

  .subscription-email {
    width: 100%;
    height: 30%;
    border-radius: 40px;
    font-size: 18px;
    padding: 14px 24px;
    border: none;
    appearance: none;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px, rgba(0, 0, 0, 0.08) 0px 1px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px, rgba(0, 0, 0, 0.02) 0px 8px 50px;
  }

  .subscription-button {
    color: rgb(255, 255, 255);
    border: none;
    width: 100%;
    height: 30%;
    padding: 14px 40px;
    background: rgb(66 66 255);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 1px, rgba(0, 0, 0, 0.1) 0px 1px 4px;
    border-radius: 40px;
    margin-left: 10px;
  }
  .subscription-form .catch-mail {
    width: 767px;
    margin: 4px auto;
    font-size: 14px;
  }

  .blog-section .banner-text-2 {
    text-align: center !important;
  }

  .product-stay-mail {
    width: 500px;
  }
}

@media (max-width: 1200px) and (min-width: 901px) {
  #categories li {
    width: 21.444%; /* = (100-3.5) / 4.5 */
    padding-bottom: 24.763%; /* =  width /0.866 */
  }
  #categories li:nth-child(8n + 5),
  #categories li:nth-child(8n + 6),
  #categories li:nth-child(8n + 7),
  #categories li:nth-child(8n + 8) {
    margin-top: -5.298%;
    margin-bottom: -5.298%;
    transform: translateX(50%) rotate(-60deg) skewY(30deg);
  }
  #categories li:nth-child(8n + 5):last-child,
  #categories li:nth-child(8n + 6):last-child,
  #categories li:nth-child(8n + 7):last-child,
  #categories li:nth-child(8n + 8):last-child {
    margin-bottom: 0;
  }
  #categories li:nth-child(8n + 5) {
    margin-left: 0.5%;
  }
  #categories li:nth-child(4n + 2) {
    margin-left: 1%;
    margin-right: 1%;
  }
  #categories li:nth-child(4n + 3) {
    margin-right: 1%;
  }
  .subscription-form {
    max-width: 692px;
    margin: 4px auto;
  }

  .subscription-email {
    width: 100%;
    height: 30%;
    border-radius: 40px;
    font-size: 18px;
    padding: 14px 24px;
    border: none;
    appearance: none;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px, rgba(0, 0, 0, 0.08) 0px 1px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px, rgba(0, 0, 0, 0.02) 0px 8px 50px;
  }

  .subscription-button {
    color: rgb(255, 255, 255);
    border: none;
    width: 100%;
    height: 30%;
    padding: 14px 40px;
    background: rgb(66 66 255);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 1px, rgba(0, 0, 0, 0.1) 0px 1px 4px;
    border-radius: 40px;
    margin-left: 10px;
  }
  .subscription-form .catch-mail {
    width: 767px;
    margin: 4px auto;
    font-size: 14px;
  }

  .blog-section .banner-text-2 {
    text-align: center !important;
  }

  .deco {
    max-width: 483px;
  }

  .wait-button {
    cursor: pointer;
    border: 10px solid transparent;
    background-image: linear-gradient(rgba(19, 20, 25, 0), rgba(19, 20, 25, 0)),
      linear-gradient(
        108.46deg,
        rgba(66, 176, 255, 0.5) 12.51%,
        rgba(103, 109, 255, 0.5) 51.13%,
        rgba(141, 138, 255, 0.5) 87.49%
      );
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    color: rgb(255, 255, 255);
    white-space: nowrap;
    background-color: transparent !important;
    padding: 0px !important;
    height: 42px;
  }

  .product-stay-mail {
    width: 500px;
  }
}

@media (max-width: 900px) and (min-width: 601px) {
  #categories li {
    width: 27.857%; /* = (100-2.5) / 3.5 */
    padding-bottom: 32.168%; /* =  width /0.866 */
  }
  #categories li:nth-child(6n + 4),
  #categories li:nth-child(6n + 5),
  #categories li:nth-child(6n + 6) {
    margin-top: -7.322%;
    margin-bottom: -7.322%;
    transform: translateX(50%) rotate(-60deg) skewY(30deg);
  }
  #categories li:nth-child(6n + 4):last-child,
  #categories li:nth-child(6n + 5):last-child,
  #categories li:nth-child(6n + 6):last-child {
    margin-bottom: 0;
  }
  #categories li:nth-child(6n + 4) {
    margin-left: 0.5%;
  }
  #categories li:nth-child(3n + 2) {
    margin-left: 1%;
    margin-right: 1%;
  }
  .subscription-form {
    max-width: 692px;
    margin: 4px auto;
  }

  .subscription-email {
    width: 100%;
    height: 30%;
    border-radius: 40px;
    font-size: 18px;
    padding: 14px 24px;
    border: none;
    appearance: none;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px, rgba(0, 0, 0, 0.08) 0px 1px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px, rgba(0, 0, 0, 0.02) 0px 8px 50px;
    margin-bottom: 13px;
  }

  .subscription-button {
    color: rgb(255, 255, 255);
    border: none;
    width: 100%;
    height: 30%;
    padding: 14px 40px;
    background: rgb(66 66 255);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 1px, rgba(0, 0, 0, 0.1) 0px 1px 4px;
    border-radius: 40px;
    margin-left: 0px;
  }
  .subscription-form .catch-mail {
    width: 767px;
    margin: 4px auto;
    font-size: 14px;
  }

  .blog-section .banner-text-2 {
    text-align: center !important;
  }

  .deco {
    max-width: 483px;
  }

  .wait-button {
    cursor: pointer;
    border: 10px solid transparent;
    background-image: linear-gradient(rgba(19, 20, 25, 0), rgba(19, 20, 25, 0)),
      linear-gradient(
        108.46deg,
        rgba(66, 176, 255, 0.5) 12.51%,
        rgba(103, 109, 255, 0.5) 51.13%,
        rgba(141, 138, 255, 0.5) 87.49%
      );
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    color: rgb(255, 255, 255);
    white-space: nowrap;
    background-color: transparent !important;
    padding: 0px !important;
    height: 42px;
  }

  .product-stay-mail {
    width: 500px;
  }
}
@media (max-width: 600px) {
  #categories li {
    width: 39.4%; /* = (100-1.5) / 2.5 */
    padding-bottom: 45.496%; /* =  width /0.866 */
  }
  #categories li:nth-child(4n + 3),
  #categories li:nth-child(4n + 4) {
    margin-top: -10.392%;
    margin-bottom: -10.392%;
    transform: translateX(50%) rotate(-60deg) skewY(30deg);
  }
  #categories li:nth-child(4n + 3):last-child,
  #categories li:nth-child(4n + 4):last-child {
    margin-bottom: 0;
  }
  #categories li:nth-child(4n + 3) {
    margin-left: 0.5%;
  }
  #categories li:nth-child(2n + 2) {
    margin-left: 1%;
  }
  .subscription-form {
    max-width: 692px;
    margin: 4px auto;
  }

  .subscription-email {
    width: 100%;
    height: 30%;
    border-radius: 40px;
    font-size: 18px;
    padding: 14px 24px;
    border: none;
    appearance: none;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px, rgba(0, 0, 0, 0.08) 0px 1px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px, rgba(0, 0, 0, 0.02) 0px 8px 50px;
    margin-bottom: 14px;
  }

  .subscription-button {
    color: rgb(255, 255, 255);
    border: none;
    width: 100%;
    height: 30%;
    padding: 14px 40px;
    background: rgb(66 66 255);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 1px, rgba(0, 0, 0, 0.1) 0px 1px 4px;
    border-radius: 40px;
    margin-left: 0px;
  }
  .subscription-form .catch-mail {
    width: 767px;
    margin: 4px auto;
    font-size: 14px;
  }

  .blog-section .banner-text-2 {
    text-align: center !important;
  }

  .deco {
    max-width: 483px;
  }

  .wait-button {
    cursor: pointer;
    border: 10px solid transparent;
    background-image: linear-gradient(rgba(19, 20, 25, 0), rgba(19, 20, 25, 0)),
      linear-gradient(
        108.46deg,
        rgba(66, 176, 255, 0.5) 12.51%,
        rgba(103, 109, 255, 0.5) 51.13%,
        rgba(141, 138, 255, 0.5) 87.49%
      );
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    color: rgb(255, 255, 255);
    white-space: nowrap;
    background-color: transparent !important;
    padding: 0px !important;
    height: 42px;
  }

  .product-stay-mail {
    width: 460px;
  }
}

/* .becomeabanner-bg-css{
  background-image: url('../images/becomemem-bg.png');
  background-position: right;

  background-repeat: no-repeat;

position: relative;

} */

#buyCoinModal .btn-close {
  background-image: url("../images/banner/cross.white.svg") !important;
}

.backers-image-sizing-css {
  width: 300px;
  height: 300px;
}
@media (max-width: 1300px) and (min-width: 992px) {
  .navbar-mega-dropdown {
    font-size: 0.8rem;
  }
}
/* amirtha */
           /* desktop  */
@media all and (min-width: 992px) {
.hackathon-form-section{
 padding-top: 7%;
 padding-bottom: 5%;
}
.new-hackathon-banner-section{
  background-image: url("../../Assests/images/bg.png");
  background-size: 100% 100%;
}
}
/* mobile */
@media (max-width: 991px) {
  .hackathon-form-section{
    padding-top: 14%;
   }
   .new-hackathon-banner-section{
    background-image: url("../../Assests/images/bg.png");
    background-size: 100% 100%;
  }
}
.hackathon-form-section .form-control{
   box-shadow: none !important;
    font-size: 1rem;
    min-height: 3rem;
    font-weight: 400;
    line-height: 1;
    color: #fff;
    background: rgba(27, 159, 254, 0.1);
    /* -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px); */
    border: 1px solid #00e5ff21;
}

.hackathon-form-section .submit-button{
  border-radius: 30px;
  padding: 5px 30px;
  background: #1b9ffe;
  border: 1px solid #00e4ff;
  color: white;
}

.hackathon-form-section ::-ms-input-placeholder { /* Edge 12-18 */
  color: white;
}

.hackathon-form-section ::placeholder {
  color: white;
  opacity: .5; /* Firefox */
}

.hackathon-form-section #floatingTextarea{
  padding: 13px;
}

.hackathon-form-section .form-check-input[type=radio] {
  border-radius: 20%;
}
.error{
  color: red;
}
.countryPicker{
  background-color: #00122600;
  /* text-align: center !important; */
  color:black !important;
}
/* .css-13cymwt-control{
  background-color: rgba(27, 159, 254, 0.1) !important;
  border: 1px solid #00e5ff21 !important;
  
 justify-content: start !important;
 padding: 6px;
}
.css-b62m3t-container{
  min-width: 452px;

  min-height: 47px !important;
}
.css-t3ipsp-control:hover{
  background-color: rgba(27, 159, 254, 0.1)!important;
  border: 1px solid #00e5ff21 !important;
  min-height: 47px !important;
} */

.css-b62m3t-container{
  width: 200%;
  min-height: 47px !important;
}
.css-1dimb5e-singleValue{
  color: white !important;
}
.css-1fdsijx-ValueContainer{
  display: flex !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.hack-top-nav-btn {
  /* animation: pulsate 1.5s infinite alternate;   */
  border: 0.1rem solid #fff;
  border-radius: 2rem;
  padding: 0.4em 1em;
  box-shadow: 0 0 .2rem #fff,
            0 0 .2rem #fff,
            0 0 2rem #efc6c6,
            0 0 0.8rem #1b9ffe2c,
            0 0 2.8rem #1b9ffe00,
            inset 0 0 1.3rem #1b9ffe25; 
}
@keyframes pulsate {
    
  100% {
    box-shadow: 0 0 .2rem #fff,
    0 0 .2rem #fff,
    0 0 2rem #f14444,
    0 0 0.8rem red,
    0 0 2.8rem red,
    inset 0 0 1.3rem rgba(255, 0, 0, 0.801); 
  
  }
  
  0% {
    box-shadow: 0 0 .2rem #fff,
    0 0 .2rem #fff,
    0 0 2rem #efc6c6,
    0 0 0.8rem rgba(255, 0, 0, 0.253),
    0 0 2.8rem rgba(255, 0, 0, 0.233),
    inset 0 0 1.3rem rgba(255, 0, 0, 0.24); 

}
}

