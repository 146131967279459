.flex-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.input-flex-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100px;
  max-width: 1000px;
  position: relative;
  z-index: 0;
}

.input {
  width: 25px;
  height: 25px;
  background-color: #00e4ff;
  position: relative;
  -webkit-box-shadow: 0 0 6px 1px #00e4ff;
  -moz-box-shadow: 0 0 6px 1px #00e4ff;
  box-shadow: 0 0 6px 1px #00e4ff;
  position: relative;
  border-radius: 50%;
}

.input:hover {
  cursor: pointer;
}
.input::before,
.input::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  background-color: #00e4ff;

  width: 8vw;
  height: 8px;

  /* max-width: 50px; */
}
.input:first-child:before {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}
.input:last-child:after {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}
.input::before {
  left: calc(-8vw);
}
.input::after {
  right: calc(-8vw);
}
.input.active {
  background-color: #00e4ff;
}
.input.active::before {
  background-color: #00e4ff;
}
.input.active::after {
  background-color: #aeb6bf;
  box-shadow: none;
}
.input.active span {
  font-weight: 700;
}
.input.active span::before {
  font-size: 13px;
}
.input.active span::after {
  font-size: 15px;
}
.input.active ~ .input,
.input.active ~ .input::before,
.input.active ~ .input::after {
  background-color: #aeb6bf;
  box-shadow: none !important;
}
.input span {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
}
.input span::before,
.input span::after {
  visibility: visible;
  position: absolute;
  left: 50%;
}
.input span::after {
  content: attr(data-year);
  top: 25px;
  transform: translateX(-50%);
  font-size: 14px;
}
.input span::before {
  content: attr(data-info);
  top: -45px;
  width: 70px;
  /* transform: translateX(-5px) rotateZ(-45deg); */
  font-size: 15px;
  text-indent: -10px;
}

.description-flex-container {
  width: 80vw;
  font-weight: 400;
  font-size: 22px;
  margin-top: 60px;
  max-width: 1000px;
}
.description-flex-container p {
  margin-top: 0;
  display: none;
}
.description-flex-container p.active {
  display: block;
}


@media (max-width: 600px) {
  .flex-parent {
    justify-content: initial;
  }

  .input-flex-container {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-top: 4vh;
  }

  .input {
    width: 30px;
    height: 30px;
    margin: 0 10px 50px;
    background-color: #aeb6bf;
  }
  .input::before,
  .input::after {
   height: 0.4px;
  }
  .input span {
    width: 100%;
    height: 100%;
    display: block;
  }
  .input span::before {
    top: calc(100% + 5px);
    transform: translateX(-50%);
    text-indent: 0;
    text-align: center;
  }
  .input span::after {
    top: 50%;
    transform: translate(-50%, -50%);
    color: #ecf0f1;
  }

  .description-flex-container {
    margin-top: 30px;
    text-align: center;
  }
}


.history-tl-container ul.tl {
  margin: 20px 0;
  padding: 0;
  display: inline-block;
}

.history-tl-container ul.tl li:last-child {
  border-left: 0;
}
.history-tl-container ul.tl li::before {
  position: absolute;
  left: -10px;
  top: -5px;
  content: " ";
  border: 8px solid rgba(255, 255, 255, 0.74);
  border-radius: 500%;
  background: #258cc7;
  height: 20px;
  width: 20px;
  transition: all 500ms ease-in-out;
}
.history-tl-container ul.tl li:hover::before {
  border-color: #258cc7;
  transition: all 1000ms ease-in-out;
}
ul.tl li .item-title {
  font-size: 16px;
}
ul.tl li .item-detail {
  font-size: 12px;
}

.description-flex-container p {
  margin-bottom: 0px;
  font-size: 12px;
}

.description-flex-container p.active {
  animation-name: swiperCatchAnimation2;
  animation-duration: 0.8s;
  animation-delay: 0.9s;
  animation-fill-mode: both;
}

/* Animations */
@keyframes swiperCatchAnimation2 {
  0% {
    opacity: 0;
    transform: translatex(-450px);
  }

  100% {
    opacity: 1;
    transform: translatex(0px);
  }
}

/* ============ DESKTOP-VIEW ============  */

@media all and (min-width: 992px) {
  .history-tl-container ul.tl li {
    list-style: none;
    margin: auto;
    min-height: 50px;
    /*background: rgba(255,255,0,0.1);*/
    border-left: 1px dashed #86d6ff;
    padding: 0 0 50px 30px;
    position: relative;
  }
  .description-flex-container p{
    width: 60%;
    margin: auto !important;
    background: rgba(27, 159, 254, 0.1);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    padding-top: 30px;
    border: 1px solid #00e5ff21;
    border-radius: 12px;
  }
  .history-tl-container {
    width: 70%;
    font-family: "Roboto", sans-serif;
    margin: auto;
    display: block;
    position: relative;
  }
}
/* ============ MOBILE-VIEW ============ */

@media (max-width: 991px) {
  .history-tl-container ul.tl li {
    list-style: none;
    margin: auto;
    min-height: 50px;
    /*background: rgba(255,255,0,0.1);*/
    border-left: 1px dashed #86d6ff;
    padding: 0 0 10px 10px;
    position: relative;
  }
  .description-flex-container p{
    margin: auto !important;
    background: rgba(27, 159, 254, 0.1);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    padding-top: 30px;
    border: 1px solid #00e5ff21;
    border-radius: 12px;
  }
  .history-tl-container {
    width: 90%;
    font-family: "Roboto", sans-serif;
    margin: auto;
    display: block;
    position: relative;
  }
}